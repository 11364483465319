import * as React from 'react'

import { useSiteConfig } from '@thg-commerce/enterprise-core'
import {
  GlobalFourButtonLink,
  GlobalMultiButton,
} from '@thg-commerce/enterprise-network/src/generated/graphql'
import {
  ButtonCollection,
  ButtonCollectionProps,
} from '@thg-commerce/enterprise-widget-button-collection'
import { ButtonEmphases } from '@thg-commerce/gravity-elements'
import { useTheme } from '@thg-commerce/gravity-theme'

import { BaseWidgetProps } from '../types'
import { WidgetTypes } from '../WidgetTypes'

export type ButtonCollectionWidgetRendererProps = BaseWidgetProps &
  (Omit<GlobalMultiButton, 'query'> | Omit<GlobalFourButtonLink, 'query'>)

enum EMPHASIS_SELECTOR {
  primary = 'high',
  secondary = 'medium',
  tertiary = 'low',
}

export const ButtonCollectionWidgetRenderer = (
  props: ButtonCollectionWidgetRendererProps,
) => {
  const theme = useTheme()
  const { widgetButtonCollectionHidePaddingBottom } = useSiteConfig()

  if (props.__typename === WidgetTypes.GlobalMultiButton) {
    if (
      !props.multiButtonWidget_itemOneCTAText &&
      !props.multiButtonWidget_itemOneURL &&
      !props.multiButtonWidget_itemTwoCTAText &&
      !props.multiButtonWidget_itemTwoURL
    ) {
      return null
    }
    const buttonCollectionProps: ButtonCollectionProps = {
      widgetButtonCollectionHidePaddingBottom,
      horizontalAlignment: 'center',
      buttons: [
        {
          text: props.multiButtonWidget_itemOneCTAText || '',
          link: props.multiButtonWidget_itemOneURL || '',
          emphasis: EMPHASIS_SELECTOR[
            props.multiButtonWidget_buttonStyle || 'medium'
          ] as ButtonEmphases,
        },

        {
          text: props.multiButtonWidget_itemTwoCTAText || '',
          link: props.multiButtonWidget_itemTwoURL || '',
          emphasis: EMPHASIS_SELECTOR[
            props.multiButtonWidget_buttonStyle || 'medium'
          ] as ButtonEmphases,
        },

        {
          text: props.multiButtonWidget_itemThreeCTAText || '',
          link: props.multiButtonWidget_itemThreeURL || '',
          emphasis: EMPHASIS_SELECTOR[
            props.multiButtonWidget_buttonStyle || 'medium'
          ] as ButtonEmphases,
        },

        {
          text: props.multiButtonWidget_itemFourCTAText || '',
          link: props.multiButtonWidget_itemFourURL || '',
          emphasis: EMPHASIS_SELECTOR[
            props.multiButtonWidget_buttonStyle || 'medium'
          ] as ButtonEmphases,
        },
        {
          text: props.multiButtonWidget_itemFiveCTAText || '',
          link: props.multiButtonWidget_itemFiveURL || '',
          emphasis: EMPHASIS_SELECTOR[
            props.multiButtonWidget_buttonStyle || 'medium'
          ] as ButtonEmphases,
        },
        {
          text: props.multiButtonWidget_itemSixCTAText || '',
          link: props.multiButtonWidget_itemSixURL || '',
          emphasis: EMPHASIS_SELECTOR[
            props.multiButtonWidget_buttonStyle || 'medium'
          ] as ButtonEmphases,
        },
      ],
    }
    return <ButtonCollection {...buttonCollectionProps} />
  }

  if (props.__typename === WidgetTypes.GlobalFourButtonLink) {
    const buttonCollectionProps: ButtonCollectionProps = {
      horizontalAlignment: 'center',
      buttons: [
        {
          text: props.buttonTextOne || '',
          link: props.buttonUrlOne || '',
          emphasis: theme.widget.fourButtonLink?.buttonEmphasis || 'medium',
        },
        {
          text: props.buttonTextTwo || '',
          link: props.buttonUrlTwo || '',
          emphasis: theme.widget.fourButtonLink?.buttonEmphasis || 'medium',
        },
        {
          text: props.buttonTextThree || '',
          link: props.buttonUrlThree || '',
          emphasis: theme.widget.fourButtonLink?.buttonEmphasis || 'medium',
        },
        {
          text: props.buttonTextFour || '',
          link: props.buttonUrlFour || '',
          emphasis: theme.widget.fourButtonLink?.buttonEmphasis || 'medium',
        },
      ],
    }
    return <ButtonCollection {...buttonCollectionProps} />
  }
  return null
}
