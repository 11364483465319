import * as React from 'react'

import { HorizontalAlignment } from '@thg-commerce/enterprise-core'
import { mq, spacing, styled, Text } from '@thg-commerce/enterprise-theme'
import { Button, ButtonEmphases } from '@thg-commerce/gravity-elements'
import { withPrefetch } from '@thg-commerce/gravity-system/prefetch'

export interface ButtonProps {
  text: string
  link: string
  emphasis?: ButtonEmphases
  widgetButtonCollectionHidePaddingBottom?: boolean
}

export interface ButtonCollectionProps {
  widgetButtonCollectionHidePaddingBottom?: boolean
  title?: {
    text: string
    headingLevel?: 'h1' | 'h2'
  }
  subtitle?: string
  horizontalAlignment?: HorizontalAlignment
  buttons: ButtonProps[]
}

const Container = styled.div<{
  widgetButtonCollectionHidePaddingBottom?: boolean
}>`
  max-width: ${(props) => props.theme.site.siteWidth};
  margin: 0 auto;
  padding: ${spacing(1)} ${spacing(2)};

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    padding: ${(props) =>
      props.widgetButtonCollectionHidePaddingBottom
        ? `${spacing(0)} ${spacing(4)} ${spacing(2)} ${spacing(4)}`
        : `${spacing(2)} ${spacing(4)}`};
  }
`

const TextContainer = styled.div<{
  horizontalAlign: HorizontalAlignment
}>`
  display: flex;
  flex-direction: column;
  ${(props) => props.horizontalAlign === 'center' && `align-items: center;`}
  ${(props) => props.horizontalAlign === 'right' && `align-items: end;`}
`

const Title = styled.h2`
  ${Text('large1', 'alternate')}
  color: ${(props) => props.theme.colors.palette.greys.darker};
`

const Subtitle = styled.p<{
  horizontalAlign: HorizontalAlignment
}>`
  ${Text('bodyText', 'default')}
  color: ${(props) => props.theme.colors.palette.greys.darker};
  margin-top: ${spacing(1)};
  text-align: ${(props) => props.horizontalAlign};
  width: 90%;

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    width: 70%;
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    width: 50%;
  }
`

const StyledList = styled.ul<{
  horizontalAlign: HorizontalAlignment
}>`
  display: flex;
  flex-direction: ${(props) =>
    props.theme.widget.ButtonCollection.itemsPerRow.xs === 1
      ? 'column'
      : 'row'};
  flex-wrap: wrap;
  justify-content: ${(props) => props.horizontalAlign};
  text-align: ${(props) => props.horizontalAlign};
  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    margin-right: -${spacing(4)};
    flex-direction: row;
  }
`

const StyledListItem = styled.li<{
  flexGrow?: boolean
}>`
  margin-top: ${spacing(3)};

  ${(props) => mq(props.theme.breakpointUtils.map, 'xs')} {
    flex-basis: calc(
      100% / ${(props) => props.theme.widget.ButtonCollection.itemsPerRow.xs} -
        ${spacing(2)}
    );
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    margin-right: ${spacing(4)};
    flex-basis: calc(
      100% / ${(props) => props.theme.widget.ButtonCollection.itemsPerRow.sm} -
        ${spacing(4)}
    );
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    flex-basis: calc(
      100% / ${(props) => props.theme.widget.ButtonCollection.itemsPerRow.md} -
        ${spacing(4)}
    );

    ${(props) => props.flexGrow && 'flex-grow: 1;'}
  }
`

const StyledButton = styled(Button)`
  height: 100%;
  width: 100%;
`
const PrefetchStyledButton = withPrefetch('href', StyledButton)

export const ButtonCollection = React.memo((props: ButtonCollectionProps) => {
  if (!props.buttons) return null
  const buttons = props.buttons.map(
    (button, index) =>
      button.text &&
      button.link && (
        <StyledListItem key={index} flexGrow={props.buttons.length < 4}>
          <PrefetchStyledButton
            renderedAs="a"
            emphasis={button.emphasis || 'medium'}
            href={button.link}
          >
            {button.text}
          </PrefetchStyledButton>
        </StyledListItem>
      ),
  )

  return (
    <Container
      widgetButtonCollectionHidePaddingBottom={
        props.widgetButtonCollectionHidePaddingBottom
      }
    >
      {(props.title || props.subtitle) && (
        <TextContainer horizontalAlign={props.horizontalAlignment || 'center'}>
          {props.title && (
            <Title as={props.title.headingLevel || 'h2'}>
              {props.title.text}
            </Title>
          )}
          {props.subtitle && (
            <Subtitle horizontalAlign={props.horizontalAlignment || 'center'}>
              {props.subtitle}
            </Subtitle>
          )}
        </TextContainer>
      )}
      <StyledList horizontalAlign={props.horizontalAlignment || 'center'}>
        {buttons}
      </StyledList>
    </Container>
  )
})
